.custom-select-antd-no-padding-bhyt>.ant-select-selector {
  border-radius: 9px !important;
  width: 100% !important;
  height: 46px !important;
  align-items: center !important;
  font-size: 14px !important;
  background-color: #ffffff !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.custom-modal-antd > .ant-modal-content > .ant-modal-body {
  padding-right: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.custom-modal-antd > .ant-modal-content{
  border-radius: 10px 10px 0px 0px !important;
}

table > tr > th {
  border: 1px solid #fff;
  background: #009B4B;
  padding: 10px;
  color: #ffffff;
}

table > tr > td {
  border: 1px solid #fff;
  background: #F1F1F1;
  padding: 10px;
  color: #343A40;
}