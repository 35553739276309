.datePicker-overide .adm-picker-view {
  flex-flow: row-reverse;
}

.datePicker-overide .adm-picker-header {
  flex-shrink: 0;
  border-bottom: solid 1px var(--adm-color-border);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 4px;
  /* background: #3B8AC9 */
  ;
  color: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.datePicker-overide .adm-picker-header-button {
  font-size: var(--header-button-font-size);
  display: inline-block;
  color: white;
  padding: 8px 8px;
  font-weight: 600;
}
